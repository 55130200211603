import React from 'react';
import Container from './Container';
import H1 from '../typography/H1';
import Breadcrumb from './Breadcrumb';

export default function Example({ title, breadcrumb }) {
  return (
    <Container noMargin className="py-5">
      <Breadcrumb pages={breadcrumb} />
      <div className="mt-2 md:flex md:items-center md:justify-between">
        <div className="min-w-0 flex-1">
          <H1 className="font-bold text-brand-gray">{title}</H1>
        </div>
      </div>
    </Container>
  );
}
