import { ChevronLeftIcon, ChevronRightIcon, HomeIcon } from '@heroicons/react/solid';
import { Link } from 'gatsby';
import React from 'react';

const Breadcrumb = ({ pages, className }) => {
  return (
    <div className={className ? className : ''}>
      <nav className="sm:hidden" aria-label="Back">
        <button onClick={() => window.history.back()} className="flex items-center text-sm font-medium text-gray-500 hover:text-gray-700">
          <ChevronLeftIcon className="mr-1 -ml-1 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
          Zurück
        </button>
      </nav>
      <nav className={`hidden sm:flex ${className ? className : ''}`} aria-label="Breadcrumb">
        <ol className="flex items-center space-x-4">
          <li className="hidden md:inline">
            <div>
              <Link to="/" className="text-brand-gray hover:text-gray-700">
                <HomeIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
                <span className="sr-only">Home</span>
              </Link>
            </div>
          </li>

          {pages.map((page, i) => (
            <li className={page.hideMobile ? 'hidden md:inline' : ''} key={page.title}>
              <div className="flex items-center" key={`${page.title}-${i}`}>
                <ChevronRightIcon className={`h-5 w-5 flex-shrink-0 text-brand-gray ${i === 0 ? 'hidden md:inline' : ''}`} aria-hidden="true" />
                {page.link ? (
                  <Link to={page.link} className={`ml-4 text-xs text-brand-gray hover:text-gray-700 md:text-sm`}>
                    {page.title}
                  </Link>
                ) : (
                  <span className={`ml-4 text-xs text-brand-gray hover:text-gray-700 md:text-sm`}>{page.title.length > 30 ? `${page.title.slice(0, 30)}...` : page.title}</span>
                )}
              </div>
            </li>
          ))}
        </ol>
      </nav>
    </div>
  );
};

export default Breadcrumb;
