import React from 'react';
import { graphql } from 'gatsby';
import Layout from '~components/layouts/Default';
import PageHeader from '~components/common/PageHeader';
import Container from '~components/common/Container';
import LogoCloud from '~components/common/LogoCloud';

export default function Page({ data, location }) {
  return (
    <Layout
      location={location}
      title="Partner und Netzwerke aus dem präventiven Gesundheitsmarkt"
      desc="Unsere Partner bieten zusätzliche Lösungen für deine Herausforderungen. Sie sind eine sinnvolle Ergänzung zu unseren Entspannungskursen"
      active="Partner"
    >
      <PageHeader title="Partner" breadcrumb={[{ title: 'Partner' }]} />
      <Container>
        <LogoCloud />
      </Container>
    </Layout>
  );
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        contact {
          mail
          phone
        }
        social {
          instagram
          facebook
          anchor
        }
      }
    }
  }
`;
