import React from 'react';
import logoBbgm from '../../media/partnerlogos/bbgm.png';
import logoBlutdruck from '../../media/partnerlogos/blutdruckdaten.png';
import logoDigitalHumanBalance from '../../media/partnerlogos/digital-human-balance.png';
import logoKinetic from '../../media/partnerlogos/kinetic.png';
import H2 from '../typography/H2';
import P from '../typography/P';
import Container from './Container';
import GridTwo from './GridTwo';

const LogoCloud = () => {
  return (
    <Container className="text-brand-gray">
      <GridTwo itemsCenter className="mb-8 md:mb-16">
        <div>
          <a href="https://www.blutdruckdaten.de/" target="_blank" rel="noreferrer">
            <img src={logoBlutdruck} alt="Partner Blutdruckdaten" className="mx-auto max-h-14" />
          </a>
        </div>
        <div>
          <H2 className="mb-5 font-display">BlutdruckDaten</H2>
          <P>
            Horst Klier ist mit seiner großartigen App ein fester Partner von uns. Denn über BlutdruckDaten machst du deine Fortschritte sichtbar! Insbesondere für Bluthochdruckpatienten empfehlen wir daher den Aktionskurs des autogenen
            Trainings.
          </P>
        </div>
      </GridTwo>
      <GridTwo itemsCenter className="mb-8 md:mb-16">
        <div>
          <a href="https://www.kinetic-plus.de/gesundheitsmanagement.html" target="_blank" rel="noreferrer">
            <img src={logoKinetic} alt="Partner Kinetic" className="mx-auto max-h-16" />
          </a>
        </div>
        <div>
          <H2 className="mb-5 font-display">Kinetic+</H2>
          <P>Als fester Partner unterstützt Kinetic+ Unternehmen dabei, die Mitarbeitergesundheit zu fördern. Sie zeichnen sich durch Premiumangebote und ein großartiges Expertennetzwerk aus.</P>
        </div>
      </GridTwo>
      <GridTwo itemsCenter className="mb-8 md:mb-16">
        <div>
          <a href="https://www.digitalhumanbalance.com/" target="_blank" rel="noreferrer">
            <img src={logoDigitalHumanBalance} alt="Partner Digital Human Balance" className="mx-auto max-h-24" />
          </a>
        </div>
        <div>
          <H2 className="mb-5 font-display">DHB</H2>
          <P>In Sachen digitalem Gesundheitsmanagement ist die DHB einer unser Premiumpartner. Gemeinsam setzen wir digitales Gesundheitsmanagement um und realisieren digital entspannte Lösungen.</P>
        </div>
      </GridTwo>
      <GridTwo itemsCenter className="mb-8 md:mb-16">
        <div>
          <a href="https://bbgm.de/" target="_blank" rel="noreferrer">
            <img src={logoBbgm} alt="Logo Bundesverband Betriebliches Gesundheitsmanagement" className="mx-auto max-h-16" />
          </a>
        </div>
        <div>
          <H2 className="mb-5 font-display">BBGM</H2>
          <P>Der Bundesverband für Betrieblichesgesundheitsmanagement zeichnet sich durch seine hohe Aus- und Weiterbildungsqualität und das umfassende Gesundheitsnetzwerk aus. Gerne sind auch wir ein Teil der Premiumpartner.</P>
        </div>
      </GridTwo>
    </Container>
  );
};

export default LogoCloud;
